<template>
  <div class="activitylist_wrap">
    <div class="select_wrap">
      <!-- <el-button @click="addAct" type="primary" icon="el-icon-plus">添加活动</el-button> -->
      <div>
        <span>请输入ID：</span>
        <el-input class="ml10" :clearable="true" placeholder="请输入ID " v-model="search_id"></el-input>
      </div>
      <div>
        <span>请输入名称：</span>
        <el-input class="ml10" :clearable="true" placeholder="请输入名称 " v-model="act_name"></el-input>
      </div>
      <el-button type="primary" @click="searchChange()" class="ml10" icon="el-icon-search">搜索</el-button>
      <el-button type="success" class="ml10" icon="el-icon-download" @click="exportAct">导出表格</el-button>
    </div>
    <el-table class="mt20" :data="tableData" border stripe size="mini" style="width: 100%">
      <el-table-column fixed label="活动ID" prop="id" width="100"></el-table-column>
      <el-table-column label="活动名称" prop="actName" width="120"></el-table-column>
      <el-table-column label="浏览/报名/分享" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.viewNum}}/{{scope.row.enrollNum}}/{{scope.row.shareNum}}</span>
        </template>
      </el-table-column>
      <el-table-column label="导购员奖励豆奖金池" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.staffBonusBeanUsePoolPlus}}/{{scope.row.staffBonusBeanTotalPoolPlus}}</span>
        </template>
      </el-table-column>
      <el-table-column label="品牌奖励豆奖金池" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.brandBonusBeanUsePool}}/{{scope.row.brandBonusBeanTotalPool}}</span>
        </template>
      </el-table-column>
      <el-table-column label="品牌惩罚承诺金" width="140">
        <template slot-scope="scope">
          <span>{{scope.row.penaltyUseMoney}}/{{scope.row.penaltyTotalMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column label="导购员单项奖励" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.staffSingleBonusUseMoney}}/{{scope.row.staffSingleBonusTotalMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column label="直播间红包" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.liveRedPackageUseMoney}}/{{scope.row.liveRedPackageTotalMoney}}</span>
        </template>
      </el-table-column>
      <el-table-column label="品牌充值费" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.brandTotalCharge}}</span>
        </template>
      </el-table-column>
      <el-table-column label="导购员充值费" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.staffBonusTotalPool}}</span>
        </template>
      </el-table-column>
      <el-table-column label="财务充值费" width="120">
        <template slot-scope="scope">
          <span>{{scope.row.financeTotalCharge}}</span>
        </template>
      </el-table-column>
      <el-table-column label="活动状态" width="120" prop="status">
        <template slot-scope="scope">
          <span>{{scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-dropdown>
            <el-tag class="m10 cp" size="medium">操作</el-tag>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native="showQrCode(scope.$index, -1)">分享</el-dropdown-item> -->
              <el-dropdown-item @click.native="deleteAct(scope.row)">删除</el-dropdown-item>
              <el-dropdown-item
                @click.native="changeStatus(scope.$index)"
              >{{scope.row.status == '未开始'?"开启活动":scope.row.status == '进行中'?"结束活动":"已结束"}}</el-dropdown-item>
              <el-dropdown-item @click.native="showQrCode(scope.$index, -2)">邀请负责人</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-tag
            @click="activityManagement(scope.$index)"
            class="m10 cp"
            type="success"
            size="medium"
          >管理</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="tab-page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="activityTotal"
      ></el-pagination>
    </div>

    <el-dialog :title="qrCodeTitle" :visible.sync="qrCodeDialogVisible" width="30%" center>
      <div class="f ver fvc">
        <el-image :src="qrCodeImg"></el-image>
        <div class="demonstration">{{qrCodeLink}}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copy(qrCodeLink)">复制</el-button>
        <el-button type="primary" @click="qrCodeDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import { copyToClipboard } from "@/assets/utils.js";

export default {
  name: "activitylist", // 活动列表
  data() {
    return {
      search_id: "", // 请输入ID
      act_name: "", // 请输入名称
      tableData: [],
      pageIndex: 1, // 当前页码
      pageSize: 10, // 请求单前页码总条数
      activityTotal: 0, // 总条数
      qrCodeTitle: "",
      qrCodeImg: "",
      qrCodeLink: "",
      qrCodeDialogVisible: false,
    };
  },
  methods: {
    copy(data) {
      copyToClipboard(data);
      this.$message.success("复制成功");
    },

    // 活动管理
    activityManagement(index) {
      // 跳转到管理页
      localStorage.setItem('pcActiveName', this.tableData[index].actName);
      this.$router.push(`/activity/baseInfo/${this.tableData[index].id}`);
    },

    // 添加活动
    // addAct() {
    //   this.$router.push({ path: "/activity/add" });
    // },

    // 获取活动列表
    async getActList() {
      try {
        const {
          data: { total, list },
        } = await ActivityAjax.getActivityList({
          actName: this.act_name,
          id: this.search_id,
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.activityTotal = total;
        this.tableData = list;
      } catch (error) {
        this.$message.error(error);
      }
    },

    // 表格 pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this.getActList();
    },
    // 表格 页码请求
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getActList();
    },
    // 搜索请求
    searchChange() {
      this.pageIndex = 1;
      this.getActList();
    },
    // 导出活动列表
    async exportAct() {
      try {
        const data = await ActivityAjax.ExportActivityList();
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "活动列表.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 删除活动
    deleteAct({ id }) {
      this.$confirm(
        "删除后，本次活动所有数据将被清空，确定删除吗",
        "提示"
      ).then(async () => {
        try {
          await ActivityAjax.delActivityStart(id);
          this.$message.success("操作成功");
          await this.getActList();
        } catch (error) {
          this.$message.error(error);
        }
      });
    },
    // 开启/结束活动
    changeStatus(index) {
      const row = this.tableData[index];
      if (row.status === "已结束") return;
      if (row.status === "进行中") {
        this.$confirm("确定结束该活动吗？结束后将无法恢复", "确定吗").then(
          async () => {
            try {
              await ActivityAjax.postActivityFinish(row.id);
              this.$message.success("操作成功");
              await this.getActList();
            } catch (error) {
              this.$message.error(error);
            }
          }
        );
      } else if (row.status === "未开始") {
        this.$confirm("将开始该场活动，确定吗", "确定吗").then(async () => {
          try {
            await ActivityAjax.postActivityStart(row.id);
            this.$message.success("操作成功");
            await this.getActList();
          } catch (error) {
            this.$message.error(error);
          }
        });
      }
    },

    // 分享/邀请负责人
    showQrCode(index, type) {
      const row = this.tableData[index];
      // 分享
      if (type === -1) {
        if (!row.actPoster) {
          return this.$message.error("链接不存在");
        }
        this.qrCodeTitle = "复制分享给好友吧";
        this.qrCodeImg = row.actPoster;
        this.qrCodeLink = row.actUrl;
      } else if (type === -2) {
        // 邀请负责人
        if (!row.actManagerQrcode) {
          return this.$message.error("链接不存在");
        }
        this.qrCodeTitle = "邀请负责人";
        this.qrCodeImg = row.actManagerQrcode;
        this.qrCodeLink = row.actManagerUrl;
      }
      this.qrCodeDialogVisible = true;
    },
  },
  created() {
    console.log(process.env.NODE_ENV);
    this.$bus.$emit("breadcrumbItem", [{ name: "活动" }]);
  },
  mounted() {
    this.getActList();
  },
};
</script>

<style lang="scss">
.activitylist_wrap {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-select {
        width: 150px !important;
      }
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
